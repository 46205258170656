import axios from 'axios';

export const TEST_ENV = process.env.REACT_APP_TEST_ENV === 'true';
export const IS_US = process.env.REACT_APP_REGION === 'US';
export const IMAGES_BUCKET = IS_US
	? 'https://vf-us-images.s3.eu-central-1.amazonaws.com/'
	: 'https://vf-uk-images.s3.eu-central-1.amazonaws.com/';

let BASE_API;
if (process.env.REACT_APP_STRAPI_HOST) {
	BASE_API = process.env.REACT_APP_STRAPI_HOST;
} else {
	BASE_API = IS_US ? TEST_ENV ? 
	'https://test-appadmin.vegan-friendly.com/'
	: 'https://appadmin.vegan-friendly.com/'
	: TEST_ENV
		? 'https://test-appadmin.vegan-friendly.co.uk/'
		: 'https://appadmin.vegan-friendly.co.uk/';
}

export { BASE_API };

const API_URI = BASE_API + 'api/'
const IL_API_URI = 'https://app.vegan-friendly.co.il/';
const VF_WEBSITE_URI = 'https://vegan-friendly.co.il/api/';

const API_HASH_PREFIX = '?hash=';
const API_DEBUG = false;

export class Api {
	static _credentials = {
		login: null,
		password: null,
		hash: null,
	};

	///////////////////////////////////////////////////////////////////////////
	// Support
	///////////////////////////////////////////////////////////////////////////

	static async supportTicketsList() {
		const res = await Api._doGet('support/list');
		return { result: 'success', list: res.list };
	}
	static getAllSuggestedEntitiesByCountry = async (country) =>
		await Api._doGet(`get-all-suggested-entities?country=${country}`);

	static updateSuggestedEntityStatus = async (
		edit_status,
		id,
		country,
		email,
		isNew,
		entity_type,
		user
	) =>
		await Api._doPut('update-suggested-entity-status', {
			edit_status,
			id,
			country,
			email,
			isNew,
			entity_type,
			user,
		});



	///////////////////////////////////////////////////////////////////////////
	// Contact
	///////////////////////////////////////////////////////////////////////////

	static async contactTicketsList() {
		try {
			const response = await Api._doGet('user-contacts');

			if (response && response.data) {
				return { result: 'success', list: response.data };
			} else {
				return { result: 'error', list: [] };
			}
		} catch (e) {
			console.log('our contact error: ' + JSON.stringify(e));
			return { result: 'error' };
		}
	}



	///////////////////////////////////////////////////////////////////////////
	// Technical
	///////////////////////////////////////////////////////////////////////////

	static async bustCachedMethod(model, params) {
		return await Api._doPost('bust-cached-method', { model, ...params });
	}

	static async clearAllCachedMethod() {
		return Api._doPost('clear-all-cache');
	}

	static async clearCachedMethod(path) {
		return Api._doPost('clear-cached-method', { path });
	}

	static async sendEmailToBusiness(subject, content, email, replyTo, isRTL) {
		console.log(subject, email, replyTo, 'subject,  email, replyTo');
		return await Api._doPost('send-email-to-business', {
			subject,
			content: encodeURI(content),
			email,
			replyTo,
			isRTL,
		});
	}

	static async updateRestaurantDaysSinceLastContact(id) {
		console.log('hey here!');
		return await Api._doPost('update-restaurant-last-contact', {
			id,
			date_of_last_contact: new Date(),
		});
	}

	static async updateBusinessDaysSinceLastContact(id) {
		console.log('hey here!');
		return await Api._doPost('update-business-last-contact', {
			id,
			date_of_last_contact: new Date(),
		});
	}

	static async getCachedMethods() {
		return await Api._doGet('get-cached-methods');
	}

	static async sendPushNotification(
		users,
		title,
		content,
		data,
		isAnon,
		country,
		privilege,
		language
	) {
		console.log('users?',users)
		return await Api._doPost('send-push-notification', {
			users,
			title,
			content,
			data,
			isAnon,
			country,
			privilege,
			language,
		});
	}

	static async approveReview(reviewId, entity_id, entity_type) {
		const path = `approve-review`;
		try {
			const response = await Api._doPost(path, { id: reviewId, entity_id, entity_type });
			return { result: 'success', reviews: response.data };
		} catch (e) {
			return { result: 'error' };
		}
	}

	static async declineReview(reviewId, restId) {
		const path = `reject-review`;
		try {
			const response = await Api._doPost(path, { id: reviewId, rest_id: restId });

			return { result: 'success', reviews: response.data };
		} catch (e) {
			return { result: 'error' };
		}
	}

	///////////////////////////////////////////////////////////////////////////
	// Restaurants
	///////////////////////////////////////////////////////////////////////////

	static async restaurantsList() {
		const res = await Api._doGet('restaurants/list');
		return { result: 'success', list: res };
	}

	static async reloadRestaurants() {
		const res = await Api._doGet('reload/the/restaurants');
		return res;
	}


	///////////////////////////////////////////////////////////////////////////
	// Users
	///////////////////////////////////////////////////////////////////////////

	static async usersGetList() {
		return await Api._doGet('get-users-brief');
	}

	static async getBenefitsBrief() {
		return await Api._doGet('get-benefits-brief');
	}

	static async usersGetCount() {
		return await Api._doGet('user-count');
	}

	static async usersGetCountDaily() {
		return await Api._doGet('user-count-daily');
	}

	static async usersSignOutSubscriber(subscriberId) {
		return await Api._doGet('user/subscriber-signout/' + subscriberId);
	}

	static async usersDeleteUser(userId) {
		return await Api._doGet('user/remove/' + userId);
	}

	static async adminsGetList() {
		return await Api._doGet('admins-list');
	}

	///////////////////////////////////////////////////////////////////////////
	// Admins (for superadmin)
	///////////////////////////////////////////////////////////////////////////

	static async adminsCreateAdmin(username, password, full_name) {
		return await Api._doPost('admins/add', { username, password, full_name });
	}

	static async adminsEditAdmin(adminId, username, password) {
		return await Api._doPost('admins/edit/' + adminId, { username, password });
	}

	static async adminsDeleteAdmin(adminId) {
		return await Api._doGet('admins/remove/' + adminId);
	}

	///////////////////////////////////////////////////////////////////////////
	// Login
	///////////////////////////////////////////////////////////////////////////

	static async testHash() {
		return await Api._doGet('test-hash');
	}

	static async generateHash(string) {
		return await Api._doPost('get-salted-hash', { string });
	}

	static async reviewsList() {
		try {
			const response = await Api._doGet('get-all-unapproved-reviews');

			if (response && response.data) {
				console.log('our review list; ', response.data.slice(0, 5));
				return { result: 'success', list: response.data };
			} else {
				return { result: 'error', list: [] };
			}
		} catch (e) {
			console.log('our error: ' + JSON.stringify(e));
			return { result: 'error' };
		}
	}
	
	static async reviewsListGraphQL() {
		return this.doGraphQLQuery(`
		  query {
			reviews {
			  data {
				id
				attributes {
				  description
				  score
				  created
				  reviewer_name
				  pics {
				  	data {
					  id
					  attributes {
						url
					  }
					}			
				  }
				  restaurant {
					data {
					  id
					  attributes {
						restaurant_name
					  }
					}
				  }
				  hash_tags {
					data {
					  id
					  attributes {
						name
					  }
					}
				  }
				  app_user {
					data {
					  id
					  attributes {
						installation_id
						email
						first_name
						last_name
						gender
						phone_number
						reviews {
						  data {
							id
							attributes {
							  reviewer_name
							}
						  }
						}
					  }
					}
				  }
				}
			  }
			}
		  }
		`);
	  }
	
	  static async doGraphQLQuery(query) {
		try {
		  const response = await axios.post(
			BASE_API + "graphql",
			{ query },
			{
			  headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + Api._credentials.hash,
			  },
			}
		  );
	  
		  if (response.data.errors) {
			console.error("GraphQL errors:");
			response.data.errors.forEach((error) => {
			  console.error(`Error message: ${error.message}`);
			});
			return { result: "error", errors: response.data.errors };
		  }
	  
		  return { result: "success", data: response.data.data };
		} catch (e) {
		  if (e.request) {
			// Request was made but no response was received
			console.error("No response received:", e.request);
		  } else {
			// Something happened in setting up the request
			console.error("Error setting up request:", e);
		  }
		  return { result: "error", message: e.message };
		}
	  }

	static async login(login, password) {
		const path = `auth/local`;

		const noCredentials = true;
		const response = await Api._doPost(
			path,
			{ identifier: login, password: password },
			noCredentials
		);

		if (response.success && response.data.jwt) {
			const user = response.data.user;
			Api._credentials = {
				login,
				password,
				hash: response.data.jwt,
			};

			console.log('user.role.name', JSON.stringify(user));

			return { result: 'success', hash: response.data.jwt, name: user.name }; //, superAdmin: user.role.name === 'super-admin' };
		} else {
			return { result: 'error' };
		}
	}

	static async logout() {
		return await Api._doGet('logout');
	}

	///////////////////////////////////////////////////////////////////////////
	// -- UTILS --
	///////////////////////////////////////////////////////////////////////////


	static async _doGet(path, data = {}, noCredentials = false) {

		console.log('Api._credentials.hash: ', 'Bearer ' + Api._credentials.hash)

		let _headers = {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache, max-age=0',
			Accept: 'application/json',
		};

		if (!noCredentials) {
			_headers['Authorization'] = 'Bearer ' + Api._credentials.hash;
		}

		const headers = Object.assign({}, _headers);

		try {
			const random = Math.floor(Math.random() * 1000000).toString();
			const fullPath = new URL(API_URI + path);
			fullPath.searchParams.append("random", random); // to avoid cache in Strapi
			console.log('API_URI + path, { headers }: ', fullPath, { headers })
			const response = await axios.get(fullPath, { headers });
			return { success: true, data: response.data };
		} catch (e) {
			return { success: false };
		}
	}

	static async _doPost(path, data = {}, noCredentials = false) {
		let _headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};

		if (!noCredentials) {
			_headers['Authorization'] = 'Bearer ' + Api._credentials.hash;
		}

		const headers = Object.assign({}, _headers);

		const url = API_URI + path;
		try {
			const response = await axios.post(url, data, { headers });
			return { success: true, data: response.data };
		} catch (e) {
			console.error(`failed to post url "${url}" -`, e.message);
			return { success: false, error: e.message };
		}
	}
	static async _doPut(path, data = {}, noCredentials = false) {
		let _headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		};

		if (!noCredentials) {
			_headers['Authorization'] = 'Bearer ' + Api._credentials.hash;
		}

		const headers = Object.assign({}, _headers);

		try {
			const response = await axios.put(API_URI + path, data, { headers });
			return { success: true, data: response.data };
		} catch (e) {
			return { success: false };
		}
	}

	static _noCredentials() {
		return { result: 'error', errorType: 'noHash', error: 'No admin hash supplied' };
	}

	static _requestError(error) {
		return { result: 'error', error: error.message ? error.message : error };
	}

	/// MIGRATION SCRIPT ///

	static uploadPhoto = async (file, name) => {
		const requestUrl = `${API_URI}upload`;
		console.log('trying to upload with url: ' + requestUrl);

		const _headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + Api._credentials.hash,
		};

		const headers = Object.assign({}, _headers);

		const formData = new FormData();

		//for(let file of files) {
		//  console.log('appending ' + file.name);
		formData.append('files', file, name);
		//}

		try {
			const response = await axios.post(requestUrl, formData, {
				headers,
			});

			if (response && response.data) {
				console.log('success with upload');

				return response.data[0];
			} else {
				return null;
			}
		} catch (e) {
			console.log('error we got!! ' + JSON.stringify(e));
		}
	};

	static getCompanies = async () => await Api._doGet('get-migration-companies');

	static getAllRestaurantsByCountry = async (country) =>
		Api._doGet(`get-all-restaurants-by-country?country=${country}&include_images=true`);

	static getAllBusinessesByCountry = async (country) =>
		Api._doGet(`get-all-businesses-by-country?country=${country}`);

	static getStrapiRestaurant = async (id) => Api._doGet(`get-strapi-restaurant/${id}`);

	static getAllUserContactsByCountry = async (country) =>
		Api._doGet(`get-all-user-contacts-by-country?country=${country}`, {}, true);

	static createReport = async (id) => await Api._doGet(`create-company-report?id=${id}`);

	static getVfServiceProviders = async () => await Api._doGet('get-vf-service-providers');

	static getVfBusinesses = async () => await Api._doGet('get-vf-businesses');

	static getVfRecipes = async () => await Api._doGet('get-vf-recipes');

	static getRestaurantData = async (id) => await Api._doGet(`get-app-restaurant/${id}`);

	static getBusinessData = async (id) => await Api._doGet(`get-business-full/${id}`);

	static getRestaurants = async () => {
		try {
			const response = await Api._doGet('get-il-restaurants-for-migration');
			return response.data.fullList;
		} catch (e) {
			return null;
		}
	};

	static migrateProducts = async (id) => await Api._doGet(`migrate-products?company_id=${id}`);

	static migrateBusiness = async (id) => await Api._doGet(`migrate-business?business_id=${id}`);

	static migrateServiceProvider = async (id) =>
		await Api._doGet(`migrate-service-provider?id=${id}`);

	static getIngredientsByName = async (name) =>
		await Api._doGet(`get-ingredients-by-name?name=${name}`);

	static updateProductIngredientsByName = async (id, product) =>
		await Api._doPost('update-product-ingredients-by-name', { id, product });

	static submitIngredient = async (ingredient) => await Api._doPost('ingredients', { ...ingredient });

	static getChains = async () => {
		try {
			const response = await Api._doGet('chains?_locale=he-IL');
			return response.data;
		} catch (e) {
			return null;
		}
	};

	static getMenuItemsForRestaurant = async (restaurantId) => {
		const method = 'GET';
		const url = `${IL_API_URI}menu_items/get/${restaurantId}?hash=b8dcbf8f1f8c3c15456dbb93010c8e3e`;
		console.log('trying to do get with URL: ' + url);
		try {
			const menuItems = await axios(url, { method, headers: {} });
			return menuItems.data;
		} catch (e) {
			console.log('Exception error with ' + ' - ' + JSON.stringify(e));
		}
	};

	static submitRestaurant = async (data, isChain) => {
		const jsonHeaders = {
			Authorization: 'Bearer ' + Api._credentials.hash,
			'Content-Type': 'application/json',
		};

		const headers = Object.assign({}, jsonHeaders);

		try {
			const response = await axios.post(`${API_URI}${isChain ? 'chains' : 'restaurants'}`, data, {
				headers,
			});
			return response.data;
		} catch (e) {
			return null;
		}
	};

	static submitRestaurantFromExcelWithGooglePlaces = async (data, type) => {
		const jsonHeaders = {
			Authorization: 'Bearer ' + Api._credentials.hash,
			'Content-Type': 'application/json',
		};

		const headers = Object.assign({}, jsonHeaders);

		try {
			const path =
				type === 'restaurant'
					? 'add-restaurant-from-excel-with-google-places'
					: 'add-chain-from-excel';
			
			const response = await axios.post(`${API_URI}${path}`, data, { headers });
			console.log('got this response.data: ', JSON.stringify(response.data));
			return response.data;
		} catch (e) {
			console.log('got an exception: ', e);
			return {};
		}
	};

	static submitProduct = async (product) => {
		console.log('trying to add product: ', product.name);
		const jsonHeaders = {
			Authorization: 'Bearer ' + Api._credentials.hash,
			'Content-Type': 'application/json',
		};

		const headers = Object.assign({}, jsonHeaders);
		console.log("this is the product we're submitting: " + JSON.stringify(product));

		try {
			const response = await axios.post(`${API_URI}products`, product, { headers });
			return response.data;
		} catch (e) {
			console.log(
				'submitRestaurant Exception error with this object ' +
				JSON.stringify(e) +
				': ' +
				JSON.stringify(product)
			);
		}
	};

	static submitChain = async (data) => {
		console.log('trying to post :  ' + data.name);

		const jsonHeaders = {
			Authorization: 'Bearer ' + Api._credentials.hash,
			'Content-Type': 'application/json',
		};

		const headers = Object.assign({}, jsonHeaders);

		try {
			const response = await axios.post(`${API_URI}chains`, data, { headers });
			return response.data;
		} catch (e) {
			console.log('SubmitChain Exception error with: ' + JSON.stringify(data) + ' - ' + e);
		}
	};

	static submitReview = async (review) => {
		let config = {
			method: 'post',
			url: `${API_URI}reviews`,
			headers: {
				Authorization: 'Bearer ' + Api._credentials.hash,
				'Content-Type': 'application/json',
			},
			data: review,
		};

		try {
			axios(config)
				.then(function (response) {
					return response;
				})
				.catch(function (error) {
					console.log('Posting error with ' + JSON.stringify(review) + ' - ' + error);
					return null;
				});
		} catch (e) {
			console.log('Bottom Exception error with ' + review.reviewer_name + ' - ' + e);
		}
	};

	static submitReviewsForRestaurant = async (reviewItems) => {
		let reviewObjects = [];
		for (let reviewItem of reviewItems) {
			console.log('about to submit object: ' + JSON.stringify(reviewItem.created));
			const reviewObject = await this.submitReview(reviewItem);
			await new Promise((r) => setTimeout(r, 1000));
		}

		return reviewObjects;
	};

	static getReviewsForRestaurant = async (restaurantId, branchId) => {
		const method = 'GET';
		const url = `${IL_API_URI}/reviews/get/${restaurantId}/${branchId}?hash=e16ec7248fc5c41cffa3ad506ad16824`;
		try {
			const reviews = await axios(url, { method, headers: {} });
			return reviews.data;
		} catch (e) {
			console.log('Exception error with ' + ' - ' + JSON.stringify(e));
		}
	};

	static getIlRestaurants = async () => {
		const method = 'GET';
		const url = `${IL_API_URI}restaurants/get-delta?hash=826c6dcfe276fe14261a499acc949daa`;

		try {
			const response = await axios(url, { method, headers: {} });
			return response.data.list;
		} catch (e) {
			console.log('Exception error with ' + ' - ' + JSON.stringify(e));
			return null;
		}
	};

	static getIlRestaurantsFull = async () => {
		const response = await Api._doGet(`get-il-restaurants`);

		if (response && response.data) {
			console.log('got this list: ' + response.data.list.length);
			return response.data.list;
		}
		return null;
	};

	static getIlRestaurantData = async (branchId) => {
		const response = await Api._doGet(`get-il-restaurant-data/${branchId}`);

		if (response) return response.data;
		return null;
	};

	static addRestaurantToVeganFriendly = async (id, restaurant_id) => {
		const response = await Api._doGet(`add-restaurant-to-vegan-friendly/${id}/${restaurant_id}`);
		if (response) {
			console.log(
				'got this response when adding through uk-backoffice: ' + JSON.stringify(response.data)
			);
			return response.data;
		}
		return { success: false };
	};

	static addChainToVeganFriendly = async (id, restaurant_id) => {
		const response = await Api._doGet(`add-chain-to-vegan-friendly/${id}/${restaurant_id}`);
		if (response) return response.data;
		return { success: false };
	};

	static emailMonthlyDiscount = async (id, hash, locale) => {
		const response = await Api._doGet(`email-monthly-discount/${id}/${hash}/${locale}`);
	};

	static getGs1ReportForCompany = async (id) => {
		const response = await Api._doGet(`get-gs1-report-for-company/${id}`);
		return response.data;
	};

	static uploadFileToS3 = async (file) => {
		const requestUrl = `${API_URI}upload`;
		console.log('trying to upload with url: ' + requestUrl);

		const _headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + Api._credentials.hash,
		};

		const headers = Object.assign({}, _headers);

		const formData = new FormData();

		formData.append('files', file);
		console.log(file);

		try {
			const response = await axios.post(requestUrl, formData, {
				headers,
			});

			if (response && response.data) {
				return response.data[0];
			} else {
				return null;
			}
		} catch (e) {
			console.log('error we got!! ' + JSON.stringify(e));
		}
	};
}
