import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Button from '../utils/Button';
import { Column, Row } from '../utils/Row';
import './Modal.css';

const notificationActions = [
	{
		value: 'explore',
		label: 'Restaurants Main Screen',
	},
	{
		value: 'restaurant',
		label: 'Specific Restaurant Screen',
	},
	{
		value: 'restaurants',
		label: 'Banner Restaurant List',
	},
	{
		value: 'discounts',
		label: 'Restaurants Benefit Screen',
	},
	{
		value: 'business',
		label: 'Specific Business Screen',
	},
	{
		value: 'businesses',
		label: 'Banner Business List',
	},
	{
		value: 'businessDiscounts',
		label: 'Business Benefits Screen',
	},
	{
		value: 'url',
		label: 'External url link',
	},
	{
		value: 'contact',
		label: 'Contact US',
	},
];

const NotificationModal = ({ userList, show, onClose, sendPushNotification, loading }) => {
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [approveText, setApproveText] = useState('');
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');
	const [action, setAction] = useState('');
	const [actionParam, setActionParam] = useState('');
	const [secondActionParam, setSecondActionParam] = useState('');

	useEffect(() => {
		const checkedSelectedUsers = [...userList];
		setSelectedUsers(checkedSelectedUsers);
	}, [userList]);

	const checkedUsers = selectedUsers.filter((s) => s.checked);
	const canSendNotification =
		approveText === 'approve' &&
		checkedUsers.length > 0 &&
		action.length > 0 &&
		title.length > 0 &&
		content.length > 0;

	const onApprove = () => {
		if (canSendNotification) {
			const checkedAppUsers = checkedUsers.filter(u => !u.anonymous).map(u => u.id)
			const checkedAnonymousUsers = checkedUsers.filter(u => u.anonymous).map(u => u.id)
			const users = { app_users: checkedAppUsers, anonymous_users: checkedAnonymousUsers }
			const data = { action: action.length > 0 ? action : null, actionParam, secondActionParam };
			console.log('our list of checked users: ', JSON.stringify(users));

			sendPushNotification(users, title, content, data);
			setApproveText('');
			setAction('');
		} else {
			console.log('not sending notification!');
		}
	};

	const onSelectAll = () => {
		setSelectedUsers([...selectedUsers.map((s) => ({ ...s, checked: true }))]);
	};

	const onUnselectAll = () => {
		setSelectedUsers([...selectedUsers.map((s) => ({ ...s, checked: false }))]);
	};

	console.log('action: ', action);

	return [
		<Modal show={ show } onHide={ onClose }>
			<Modal.Header onClick={ onClose }>
				<Modal.Title>Push Notifications</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ selectedUsers && selectedUsers.length > 0 ? (
					<div>
						<div style={ { display: 'flex', flexDirection: 'column', width: '100%' } }>
							<input
								placeholder="title"
								style={ { marginTop: 10 } }
								type="text"
								value={ title }
								onChange={ (e) => setTitle(e.target.value) }
							/>
							<input
								placeholder="content"
								style={ { marginTop: 10, marginBottom: 10 } }
								type="text"
								value={ content }
								onChange={ (e) => setContent(e.target.value) }
							/>
							<Select
								options={ notificationActions }
								onChange={ (item) => setAction(item.value) }
								placeholder="Select Action"
							/>

							<input
								placeholder="first parameter"
								style={ { marginTop: 10 } }
								type="text"
								value={ actionParam }
								onChange={ (e) => setActionParam(e.target.value) }
							/>
							<input
								placeholder="optional: second parameter"
								style={ { marginTop: 10 } }
								type="text"
								value={ secondActionParam }
								onChange={ (e) => setSecondActionParam(e.target.value) }
							/>
						</div>
						<h5>
							You're about to send a push notification to{ ' ' }
							{ selectedUsers.filter((u) => u.checked).length }/{ selectedUsers.length } users
						</h5>
						{ selectedUsers.map((user, index) => (
							<Row key={ index }>
								<Column>
									<h5>{ user.email ?? user.installation_id }</h5>
								</Column>
								<Column>
									<input
										type="checkbox"
										checked={ user.checked }
										onChange={ () =>
											setSelectedUsers([
												...selectedUsers.map((u) => ({
													...u,
													checked: u.id === user.id ? !u.checked : u.checked,
												})),
											])
										}
									/>
								</Column>
							</Row>
						)) }
						<Row>
							<h5>Please type in 'approve'</h5>
							<input
								type="text"
								value={ approveText }
								onChange={ (e) => setApproveText(e.target.value) }
							/>
						</Row>
					</div>
				) : (
					<h5>No users selected!</h5>
				) }
				<Button onClick={ onSelectAll }>Select All</Button>
				<Button onClick={ onUnselectAll }>Unselect All</Button>
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={ !canSendNotification } variant="primary" onClick={ onApprove }>
					{ loading ? 'Loading...' : 'Approve' }
				</Button>
				<Button variant="secondary" onClick={ onClose }>
					Close
				</Button>
			</Modal.Footer>
		</Modal>,
	];
};

export default NotificationModal;
